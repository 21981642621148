<template>
  <section>
    <ContentCard data-auto-id="session-list" title="Active Sessions">
      <div class="pt-8 px-4 pb-4">
        <ItemTable data-auto-id="session-table" :items="sessions" :columns="tableColumns">
          <template #current="{itemData}">
            <div class="flex justify-center items-center text-tozny">
              <i v-if="itemData" class="material-icons micon" title="Current Session">label</i>
            </div>
          </template>
          <template #applications="{itemData}">
            <ul>
              <li v-for="app in itemData" v-bind:key="app.clientID">{{app.clientName ? app.clientName : app.clientId}}</li>
            </ul>
          </template>
          <template #date="{itemData}">
            <span class="text-tiny">{{ formatDate(itemData) }}</span>
          </template>
          <template #expire="{itemData}">
            <div class="h-8 flex items-center justify-center">
              <ToznyLoader class="text-tozny w-5 h-5" v-if="expireQueue[itemData]" />
              <button
                v-else
                class="py-1 px-6 m-0 text-lil min-w-0"
                @click.prevent="$emit('expire', itemData)"
              >
                Expire
              </button>
            </div>
          </template>
        </ItemTable>
        <div class="mt-8 flex justify-center">
          <button class="mb-0" @click.prevent="$emit('expireAll')">Expire Other Sessions</button>
        </div>
      </div>
    </ContentCard>
  </section>
</template>

<script>
import { format } from 'date-fns'
import ContentCard from '../../../Common/ContentCard'
import ItemTable from '../../../Common/ItemTable'
import ToznyLoader from '../../../Common/ToznyLoader'
export default {
  name: 'SessionList',
  components: {
    ContentCard,
    ItemTable,
    ToznyLoader,
  },
  props: {
    sessions: {
      type: Array,
      default: () => [],
      validator(items) {
        for (let item of items) {
          if (typeof item.ipAddress !== 'string') {
            return false
          }
          if (typeof item.started !== 'number') {
            return false
          }
        }
        return true
      },
    },
    expireQueue: Object,
    errorMessage: String,
  },
  computed: {
    tableColumns() {
      return [
        {
          title: '',
          target: 'current',
          slot: 'current',
          className: 'whitespace-no-wrap w-0 text-center',
        },
        {
          title: 'Applications',
          target: 'clients',
          slot: 'applications',
        },
        {
          title: 'IP Address',
          target: 'ipAddress',
          slot: 'ipAddress',
        },
        {
          title: 'Started',
          target: 'started',
          slot: 'date',
        },
        {
          title: 'Expires',
          target: 'expires',
          slot: 'date',
        },
        {
          title: 'Last Access',
          target: 'lastAccess',
          slot: 'date',
        },
        {
          title: '',
          target: 'id',
          slot: 'expire',
          className: 'whitespace-no-wrap w-8 text-center',
        },
      ]
    },
  },
  methods: {
    handleAction(action) {
      this.$emit(action.event, action.token)
    },
    handleCardActions(action) {
      this.$emit(action.event)
    },
    actionObject(token) {
      return [
        {
          token: token,
          event: 'delete',
          altText: 'delete token',
          icon: 'delete-outline',
        },
      ]
    },
    formatDate(datetime) {
      return format(new Date(datetime * 1000), 'PPpp')
    }
  },
}
</script>
