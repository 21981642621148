<template>
  <MainLayout>
    <Banner v-if="hasError" type="red" icon="error">
      {{ errorMessage }}
    </Banner>
    <SessionList
      v-if="status === 'idle'"
      :sessions="sessions"
      :expire-queue="expireQueue"
      @expire="expireSession"
      @expireAll="expireAll"
    />
    <div
      v-else-if="status === 'loading'"
      class="w-full flex items-center justify-center"
    >
      <ToznyLoader class="text-tozny w-24 h-24" />
    </div>
  </MainLayout>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

import MainLayout from '@/Common/MainLayout/MainLayout'
import SessionList from './Components/SessionList'
import ToznyLoader from '@/Common/ToznyLoader'
import Banner from '@/Common/Banner'

export default {
  name: 'Sessions',
  components: {
    Banner,
    MainLayout,
    SessionList,
    ToznyLoader,
  },
  computed: {
    ...mapState('sessions', [
      'status',
      'sessions',
      'expireQueue',
      'errorMessage',
    ]),
    ...mapGetters('sessions', ['hasError']),
  },
  methods: {
    ...mapActions('sessions', ['initialize', 'expireSession', 'expireAll']),
  },
  created: async function() {
    await this.initialize()
  },
}
</script>
