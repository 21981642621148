var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('ContentCard',{attrs:{"data-auto-id":"session-list","title":"Active Sessions"}},[_c('div',{staticClass:"pt-8 px-4 pb-4"},[_c('ItemTable',{attrs:{"data-auto-id":"session-table","items":_vm.sessions,"columns":_vm.tableColumns},scopedSlots:_vm._u([{key:"current",fn:function(ref){
var itemData = ref.itemData;
return [_c('div',{staticClass:"flex justify-center items-center text-tozny"},[(itemData)?_c('i',{staticClass:"material-icons micon",attrs:{"title":"Current Session"}},[_vm._v("label")]):_vm._e()])]}},{key:"applications",fn:function(ref){
var itemData = ref.itemData;
return [_c('ul',_vm._l((itemData),function(app){return _c('li',{key:app.clientID},[_vm._v(_vm._s(app.clientName ? app.clientName : app.clientId))])}),0)]}},{key:"date",fn:function(ref){
var itemData = ref.itemData;
return [_c('span',{staticClass:"text-tiny"},[_vm._v(_vm._s(_vm.formatDate(itemData)))])]}},{key:"expire",fn:function(ref){
var itemData = ref.itemData;
return [_c('div',{staticClass:"h-8 flex items-center justify-center"},[(_vm.expireQueue[itemData])?_c('ToznyLoader',{staticClass:"text-tozny w-5 h-5"}):_c('button',{staticClass:"py-1 px-6 m-0 text-lil min-w-0",on:{"click":function($event){$event.preventDefault();return _vm.$emit('expire', itemData)}}},[_vm._v(" Expire ")])],1)]}}])}),_c('div',{staticClass:"mt-8 flex justify-center"},[_c('button',{staticClass:"mb-0",on:{"click":function($event){$event.preventDefault();return _vm.$emit('expireAll')}}},[_vm._v("Expire Other Sessions")])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }